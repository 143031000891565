<template>
  <v-expansion-panels v-model="selected" accordion flat>
    <v-expansion-panel readonly>
      <v-expansion-panel-header class="pa-0 forHover">
        <template v-slot:actions>
          <v-icon v-show="false">
            mdi-chevron-right
          </v-icon>
        </template>
        <company-list-item :id="id"
                           :activeChip="activeChip"
                           :address="address"
                           :cemsCount="cemsCount"
                           :distance="distance"
                           :filterMode="filterMode"
                           :isBookmarked="isBookmarked"
                           :isShowBookmark="true"
                           :isShowChevron="isShowChevron"
                           :isShowChip="isShowChip"
                           :lat="lat"
                           :logo="logo"
                           :lon="lon"
                           :mobileCount="mobileCount"
                           :name="name"
                           :no="no"
                           :opmsCount="opmsCount"
                           :severityCems="severityCems"
                           :severityMobile="severityMobile"
                           :severityOpms="severityOpms"
                           :severityStation="severityStation"
                           :stationCount="stationCount"
                           class="pt-2 pb-1 py-sm-2 pr-0 pr-sm-2"
                           style="width: calc(100% - 24px)"
                           v-on:clickChevronRight="goToCompany"
                           v-on:clickChip="showSensor"
                           v-on:clickLabel="emitClickLabel">
        </company-list-item>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pr-6 pl-3 pt-3 pb-3">
        <sensor-table v-if="!isFetching" :height="300" :sensorData="sensorData"></sensor-table>
        <v-row v-if="isFetching" align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CompanyListItem from './CompanyListItem'
import SensorTable from './sensorTable'
import { mapState } from 'vuex'

export default {
  name: 'CompanyWithSensor',
  components: { SensorTable, CompanyListItem },
  props: ['name', 'address', 'distance', 'severityCems', 'severityOpms', 'severityMobile', 'severityStation', 'logo', 'mobileCount',
    'lat', 'lon', 'isBookmarked', 'id', 'no', 'isShowChip', 'isShowChevron', 'filterMode', 'cemsCount', 'opmsCount', 'stationCount'],
  mounted () {
    const today = new Date()
    this.todayDate = today.toISOString().split('T')[0]
  },
  data () {
    return {
      todayDate: '',
      selected: null,
      sensorData: null,
      activeChip: '',
      isFetching: false
    }
  },
  methods: {
    collapse () {
      this.selected = null
      this.activeChip = ''
    },
    emitClickLabel (e) {
      this.$emit('clickLabel', e)
    },
    goToCompany (id) {
      this.$router.push({ name: 'Company', query: { id: id } })
    },
    async showSensor (type) {
      if (type === this.activeChip) {
        this.selected = null
        this.activeChip = ''
        return false
      }
      this.isFetching = true
      this.selected = 0
      let sensorInteger = -1
      this.activeChip = type
      switch (type) {
        case 'cems':
          sensorInteger = 1
          break
        case 'opms':
          sensorInteger = 2
          break
        case 'mobile':
          sensorInteger = 3
          break
        case 'station':
          sensorInteger = 4
          break
      }
      if (sensorInteger !== -1) {
        this.sensorData = await this.fetchSensor(sensorInteger)
      }
      this.isFetching = false
    },
    async fetchSensor (type) {
      const uuid = this.uuid || ''
      // get latest data no matters which date is it.
      const payload = { id: this.id, uuid: uuid, date: '', type: type }
      return await this.$store.dispatch('sensor/getSensorData', payload)
    }
  },
  computed: {
    ...mapState('user', ['uuid'])
  }
}
</script>

<style scoped>

.forHover:hover {
  background-color: #EEEEEE !important;
  /*cursor: pointer;*/
}

::v-deep .v-expansion-panel-header {
  line-height: 1.5;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  font-weight: 400;
}

::v-deep td > .chip {
  font-size: 12px;
}
</style>
