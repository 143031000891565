<template>
  <div class="d-flex sensorPage grey lighten-3">
    <div id="detailDom" class="sensorDetail white elevation-2">
      <div id="sensorSearchHeader"
           :class="(!uuid || permission.includes('use basic search')) ? 'px-0 px-sm-3 pb-2 pt-0 pt-sm-3' : 'pt-0 pt-sm-3'"
           style="width: 100%;">
        <div class="justify-center pt-2 pb-2 d-flex d-sm-none" style="width: 100%"
             @touchend.passive="calculateHeightEnd"
             @touchmove.passive="calculateHeight"
             @touchstart.passive="calculateHeightStart">
          <div id="draggableBar" class="grey darken-1 rounded-tl-lg rounded-tr-lg"
               style="height: 4px; width: 50%;"></div>
        </div>
        <div :class="(!uuid || permission.includes('use basic search')) ? 'd-flex' : 'd-none'"
             :style="($vuetify.breakpoint.width <= 335) ? 'flex-wrap: wrap;' : ''"
             style="background-color: rgba(65, 149, 24, 0.2);">
          <div class="d-flex">
            <div class="filterUnit filterUnitLeft">
              <span class="myFilterText search--text">ประเภท</span>
              <v-icon class="myFilterIcon">mdi-filter</v-icon>
            </div>
            <div class="filterUnit filterUnitRight">
              <v-select v-model="filterType" :items="filterOption" background-color="white" color="search" dense :disabled="isFetchingSensor"
                        hide-details item-color="search-item" outlined placeholder="จุดตรวจวัด"></v-select>
            </div>
          </div>
          <div class="d-flex" :style="($vuetify.breakpoint.width <= 335) ? 'margin-top: -12px;' : ''">
            <div class="filterUnit filterUnitLeft">
              <span class="myFilterText search--text">จัดเรียงตาม</span>
              <v-icon class="myFilterIcon">mdi-sort</v-icon>
            </div>
            <div class="filterUnit filterUnitRight">
              <v-select v-model="sortBy" :items="sortSelectItems" background-color="white" color="search" dense :disabled="isFetchingSensor"
                        hide-details item-color="search-item" outlined placeholder="ไม่จัดเรียง"
                        @input="doSortList()"></v-select>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!isTouch" :style="`height: calc(100% - ${headerHeight}px);`" class="sensorListContainer"
           @scroll="onSensorListScroll">
        <span class="pl-3 grey--text" style="font-size: 12px;">อ้างอิงผลการค้นหาจาก "<u>{{ basedOn }}</u>"</span>
        <div v-for="(item, index) in sensorData" :key="index" :data-cws-id="item.id" class="pl-3 pr-1 px-sm-3">
          <company-with-sensor :id="item.id"
                               :ref="'cws'+item.id"
                               :address="item.address"
                               :cemsCount="item.countCems"
                               :distance="item.distance"
                               :filterMode="filterType"
                               :isBookmarked="item.isBookmarked"
                               :isShowChevron="permission.includes('view factory details')"
                               :isShowChip="true"
                               :lat="item.lat"
                               :logo="item.logo"
                               :lon="item.lon"
                               :mobileCount="item.countMobile"
                               :name="item.name"
                               :no="item.no"
                               :opmsCount="item.countOpms"
                               :severityCems="item.severityCems"
                               :severityMobile="item.severityMobile"
                               :severityOpms="item.severityOpms"
                               :severityStation="item.severityStation"
                               :stationCount="item.countStation"
                               v-on:clickLabel="moveMapToLocation">
          </company-with-sensor>
          <v-divider class="d-none d-sm-block"/>
        </div>
        <div v-show="this.factoryHasNextPage || isFetchingSensor" style="height: 64px;">
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
          </v-row>
        </div>
      </div>
    </div>
    <div id="mapDom" class="sensorMapContainer">
      <div id="map" class="fill-height">
        This is map
      </div>
      <div class="sensorAppBar d-flex d-sm-none pt-3 px-3">
        <div class="d-flex align-center white elevation-2 rounded-lg px-1" style="width: 100%;">
          <div class="ml-1 d-flex align-center" @click="openMenu">
            <img :src="logo2" alt="web-logo" style="width: auto; height: 26px;">
          </div>
          <div style="width: calc(100% - 58px - 24px - 28px); height: 48px;">
            <v-text-field v-if="(!uuid || permission.includes('use basic search'))" v-model="keyword" flat hide-details
                          placeholder="ชื่อหรือเลขทะเบียนโรงงาน" readonly solo @click="clickSearchField"/>
          </div>
          <v-spacer/>
          <div v-if="permission.includes('use advance search')" class="mr-1">
            <v-icon class="text--primary" @click="openFilter">mdi-dots-vertical</v-icon>
          </div>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-if="uuid" v-bind="attrs" v-on="on">
                  <v-icon v-if="!profile_picture" large>mdi-account-circle</v-icon>
                  <v-img v-else :src="profile_picture" height="36"></v-img>
                </div>
                <div v-else v-bind="attrs" v-on="on" class="mr-1" @click="openLogin">
                  <v-icon>mdi-login</v-icon>
                </div>
              </template>
              <div v-if="uuid" class="primary text-center pa-3">
                <v-icon v-if="!profile_picture" class="white--text" large>mdi-account-circle</v-icon>
                <v-img v-else :src="profile_picture" height="60"></v-img>
                <br/>
                <span class="white--text" style="font-size: 15px">{{ full_name || email }}</span>
                <br/>
                <span style="font-size: 13px; color: #cfba7d;">{{ position }}</span>
              </div>
              <div v-if="uuid" class="white rounded-b-lg pa-3 elevation-2">
                <v-btn :ripple="false" block color="grey lighten-2 search--text" elevation="0"
                       @click="emitLogoutSignal()">
                  ออกจากระบบ
                  <v-progress-circular v-show="isLoggingOut" class="ml-3" indeterminate size="16"></v-progress-circular>
                </v-btn>
              </div>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="sensorMapButton d-flex flex-column">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary white--text" fab small @click="getUserLocation">
              <v-icon small>mdi-crosshairs-gps</v-icon>
            </v-btn>
          </template>
          ตำแหน่งของฉัน
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary white--text mt-2 mt-sm-3" fab small
                   @click="isShowSearchPlace=true">
              <v-icon small>mdi-magnify</v-icon>
            </v-btn>
          </template>
          ค้นหาสถานที่
        </v-tooltip>
        <v-btn color="primary white--text mt-2 mt-sm-3" fab small
               @click="mapZoomIn()">
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
        <v-btn color="primary white--text mt-2 mt-sm-3" fab small
               @click="mapZoomOut()">
          <v-icon small>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="searchDialog" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <v-card style="border-radius: 0; height: 100%;">
        <v-card-title>
          <div class="d-flex" style="width: 100%;">
            <span class="detailHeader">ค้นหา</span>
            <v-spacer></v-spacer>
            <v-icon @click="searchDialog=false">mdi-close</v-icon>
          </div>
          <div class="pt-3" style="width: 100%;">
            <v-text-field id="dashboardSearchField" ref="dashboardSearchField" v-model="keyword"
                          class="white elevation-2" dense hide-details outlined placeholder="ชื่อหรือเลขทะเบียนโรงงาน"
                          prepend-inner-icon="mdi-magnify" rounded @keyup.enter="enterSearch()"></v-text-field>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="pt-3">
            <div v-for="(item, index) in sensorData" :key="index" class="searchListRow">
              <v-divider class="d-none d-sm-block"/>
              <company-list-item :address="item.address"
                                 :dataId="item.id"
                                 :distance="item.distance"
                                 :isBookmarked="item.isBookmarked"
                                 :lat="item.lat"
                                 :logo="item.logo"
                                 :lon="item.lon"
                                 :name="item.name"
                                 :severityCems="item.severityCems"
                                 :severityMobile="item.severityMobile"
                                 :severityOpms="item.severityOpms"
                                 :severityStation="item.severityStation"
                                 class="pt-2 pb-2 pl-1"
                                 v-on:clickLabel="moveMapToLocation"/>
            </div>
            <div v-show="sensorData.length <= 0 && !isFetchingSensor" class="pt-6 text-center">
              ไม่พบข้อมูล
            </div>
            <div v-show="this.isFetchingSensor" style="height: 64px;">
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <search-place :isShow="isShowSearchPlace" v-on:clickLabel="searchClickHandler"
                  v-on:isShowSignal="searchPlaceSignalHandler"/>
    <v-dialog v-model="openSensorTableModal" max-width="600">
      <div class="white px-3 pb-3" style="position: relative;">
        <div style="position: absolute; top: 12px; right: 12px; z-index: 99;">
          <v-icon @click="openSensorTableModal=false;">
            mdi-close
          </v-icon>
        </div>
        <div v-if="tableCountCems==0 && tableCountOpms==0 && tableCountMobile==0 && tableCountStation==0"
             class="d-flex justify-center align-center py-5">
          ไม่มีข้อมูล
        </div>
        <div class="pt-2 grey--text text--darken-2">
          {{ selectedCompanyName }}
        </div>
        <v-tabs v-model="tabSensorTable" color="search">
          <v-tab v-if="tableCountCems > 0">
            CEMS
          </v-tab>
          <v-tab v-if="tableCountOpms > 0">
            WPMS
          </v-tab>
          <v-tab v-if="tableCountMobile > 0">
            MOBILE
          </v-tab>
          <v-tab v-if="tableCountStation > 0">
            STATION
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabSensorTable" class="pt-3" touchless>
          <v-tab-item v-if="tableCountCems > 0">
            <sensor-table v-show="!isFetchingTable" :sensorData="cemsData"/>
            <div v-if="isFetchingTable" class="d-flex justify-center" style="width: 100%">
              <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
          <v-tab-item v-if="tableCountOpms > 0">
            <sensor-table v-show="!isFetchingTable" :height="300" :sensorData="opmsData"/>
            <div v-if="isFetchingTable" class="d-flex justify-center" style="width: 100%">
              <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
          <v-tab-item v-if="tableCountMobile > 0">
            <sensor-table v-show="!isFetchingTable" :height="300" :sensorData="mobileData"/>
            <div v-if="isFetchingTable" class="d-flex justify-center" style="width: 100%">
              <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
          <v-tab-item v-if="tableCountStation > 0">
            <sensor-table v-show="!isFetchingTable" :height="300" :sensorData="stationData"/>
            <div v-if="isFetchingTable" class="d-flex justify-center" style="width: 100%">
              <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CompanyWithSensor from '../components/CompanyWithSensor'
import CompanyListItem from '../components/CompanyListItem'
import SearchPlace from '../components/dialog/SearchPlace'
import SensorTable from '../components/sensorTable'
import utils from '../assets/js/utils'

export default {
  name: 'Sensor',
  components: {
    SearchPlace,
    CompanyWithSensor,
    CompanyListItem,
    SensorTable
  },
  async mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)
    const clearStatePromise = this.$store.dispatch('dashboard/clearState')

    this.pageDom = document.getElementsByClassName('sensorPage')[0]
    this.detailDom = document.getElementById('detailDom')
    this.mapDom = document.getElementById('mapDom')
    this.computeFirstLayout()
    await this.createLongdoMap()

    await clearStatePromise
    const fetchGeolocation = await this.geolocationStatus()
    if (fetchGeolocation) {
      this.getUserLocation()
    } else {
      this.map.location({ lat: 13.721874, lon: 100.530351 })
      this.map.zoom(12)
      await this.get(this.keyword)
      this.getUserLocation()
    }
  },
  activated () { // come from /company
    if (this.scrollTop) {
      this.$nextTick(() => {
        document.getElementsByClassName('sensorListContainer')[0].scrollTop = this.scrollTop
      })
    }
    this.map.resize()
    setTimeout(() => {
      this.map.resize()
    }, 500)
  },
  beforeRouteLeave (to, from, next) {
    // this page has been cached in app.vue
    // if next page is not Company, remove cache
    // for more information see
    //     App.vue :: <keep-alive>
    //     router/index.js :: meta: { keepAlive }
    //     store/index.js :: state: { keepAliveResetSignal }
    if (to.name === 'Company') {
      this.scrollTop = document.getElementsByClassName('sensorListContainer')[0].scrollTop
      next()
    } else {
      next()
      this.$store.dispatch('refreshKeepAlive')
    }
  },
  beforeDestroy () {
    // if not destroy, every time window is resize it can't find target div
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      scrollTop: null,
      todayDate: '',
      pageDom: null,
      detailDom: null,
      mapDom: null,
      filterType: '',
      filterOption: [
        {
          text: 'ทั้งหมด',
          value: ''
        },
        {
          text: 'CEMS',
          value: 'cems'
        },
        {
          text: 'WPMS',
          value: 'opms'
        },
        {
          text: 'MOBILE',
          value: 'mobile'
        },
        {
          text: 'STATION',
          value: 'station'
        }
      ],
      sortBy: 'distance',
      sortSelectItems: [
        {
          text: 'จากพิกัดอ้างอิง',
          value: 'distance'
        },
        {
          text: 'ชื่อโรงงาน (A-Z)',
          value: 'name'
        },
        {
          text: 'ชื่อโรงงาน (Z-A)',
          value: 'name-desc'
        }
      ],
      sensorData: [],
      keyword: '',
      searchDialog: false,
      isShowSearchPlace: false,
      isFetchingSensor: false,
      isTouch: false,
      logo2: require('../assets/img/ic_logo_3.png'),
      userLat: '',
      userLon: '',
      basedOn: 'แยกสาธร-นราธิวาส',
      openSensorTableModal: false,
      tabSensorTable: 0,
      cemsData: null,
      opmsData: null,
      mobileData: null,
      stationData: null,
      isFetchingTable: false,
      tableCountCems: 0,
      tableCountOpms: 0,
      tableCountMobile: 0,
      tableCountStation: 0,
      selectedCompanyName: '',
      advanceSearchData: {},
      headerHeight: 85,
      aliveDetailTop: 0,
      aliveDetailHeight: 0
    }
  },
  methods: {
    reCalculateHeight () {
      // calculate green filter height, and set factory list height.
      const dom = document.getElementById('sensorSearchHeader')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    async createLongdoMap () {
      // create longdo map
      this.map = new window.longdo.Map({
        placeholder: document.getElementById('map')
      })
      try { // manage cache longdo map layer
        await (window.longdoJsInterface.Util.cacheLongdoMapLayer(window.longdo, 'diw_front_cache', this.map))
      } catch (e) {
        console.warn('At cacheLongdoMapLayer', e)
      }
      try {
        this.map.Layers.setBase(window.longdo.Layers.POI)
        this.map.enableFilter(window.longdo.Filter.Light)
      } catch (e) {
        console.warn('At [setBase or enableFilter]', e)
      }
      this.map.Event.bind('overlayClick', (e) => {
        const overlayElement = e.element() // this element return longdo_overlay_div
        if (overlayElement) {
          const customElement = overlayElement.firstChild // this element return custom div
          const id = customElement.getAttribute('data-id')
          let targetDivs = document.querySelectorAll('[data-cws-id="' + id + '"]')
          if (targetDivs.length > 0) {
            targetDivs = targetDivs[0]
          }
          this.broadcastCollapseCWS() // collapse all factory component
          targetDivs.scrollIntoView({ behavior: 'smooth', block: 'start' }) // scroll list into clicked factory
          this.placeSensorTable(id) // show sensor dialog
        }
      })
      this.map.Ui.Zoombar.visible(false)
    },
    mapZoomIn () {
      // zoom in & animate
      this.map.zoom(true, true)
    },
    mapZoomOut () {
      // zoom out & animate
      this.map.zoom(false, true)
    },
    async geolocationStatus () {
      // check if user is granted geoLocation status, if grant return true
      const deviceType = utils.getDeviceType()
      let status = { state: 'denied' }
      if (deviceType === 3) {
        // if web browser
        try {
          status = await navigator.permissions.query({ name: 'geolocation' })
        } catch (e) {
          console.warn({ ...e, myLogger: 'navigator.permissions.query error' })
        }
        return status.state === 'granted'
      } else {
        // if web-view
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'geolocationStatus'
          })
          status = JSON.parse(result)
        } catch (e) {
          console.warn({ ...e, myLogger: 'This device does not have previous geolocationStatus.' })
        }
        return status.state === 'granted'
      }
    },
    getUserLocation () {
      // get data based on user geoLocation
      const self = this
      const deviceType = utils.getDeviceType()

      if (deviceType === 3) {
        // if web-browser
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              self.userLat = position.coords.latitude
              self.userLon = position.coords.longitude
              self.map.zoom(12)
              self.map.location({ lat: self.userLat, lon: self.userLon })
              self.clearResult()
              self.get(this.keyword)
              self.basedOn = 'ที่อยู่ของฉัน'
            }
          )
        } else {
          console.warn('Geolocation is not supported by this browser.')
        }
      } else {
        // if mobile-app, call js-interface to get location then do callback
        window.longdoJsInterface.getGeolocation()
          .then(
            data => {
              // for web-view
              self.userLat = data.latitude
              self.userLon = data.longitude
              self.map.location({ lat: self.userLat, lon: self.userLon })
              self.clearResult()
              self.get(this.keyword)
              self.basedOn = 'ที่อยู่ของฉัน'
              // save to cache that this device has been grant geoLocation
              window.longdoJsInterface.addStorage({
                key: 'geolocationStatus',
                value: JSON.stringify({ state: 'granted' })
              }).then(
                () => {},
                (error) => {
                  if (error.code !== 1) {
                    console.warn('getUserLocation :: saveStorage :: addStorage -->', error)
                  }
                }
              )
            },
            error => {
              console.warn(error)
            }
          )
      }
    },
    async clearResult () {
      this.sensorData = []
      await this.$store.dispatch('dashboard/clearState')
      this.map.Overlays.clear()
    },
    async get (keyword = '') {
      const uuid = this.uuid || ''
      let payload = {
        keyword: keyword,
        uuid: uuid,
        device: this.deviceId || '',
        lat: this.userLat,
        lon: this.userLon,
        sort: this.sortBy,
        measurement: ['cems', 'opms', 'mobile', 'station'].indexOf(this.filterType) + 1 // if select cems --> 1
      }
      payload = { ...payload, ...this.advanceSearchData }
      await this.getSensor(payload)
    },
    async enterSearch () {
      await this.clearResult()
      await this.get(this.keyword)
    },
    async advanceGet (options) {
      const uuid = this.uuid || ''
      let payload = {
        keyword: options.keyword,
        uuid: uuid,
        device: this.deviceId || '',
        lat: this.userLat,
        lon: this.userLon,
        sort: this.sortBy,
        measurement: ['cems', 'opms', 'mobile', 'station'].indexOf(this.filterType) + 1 // if select cems --> 1
      }
      payload = { ...payload, type: options.type, type_industry: options.type_industry, geocode: options.geocode, has_latest_measurement: options.has_latest_measurement, region: options.region }
      await this.getSensor(payload)
    },
    async getSensor (payload) {
      this.isFetchingSensor = true
      await this.$store.dispatch('dashboard/getFactoryList', payload)
      // await this.doFilter(this.filterType) // this.sensorData will be place here
      this.sensorData = this.allFactoryData
      if (this.sensorData.length < 14 && !this.isFetchingSensor && this.factoryHasNextPage) {
        await this.get(this.keyword)
        return // place factory next data fetch
      }
      this.placeFactory(this.sensorData) // clear all then place all
      this.isFetchingSensor = false
    },
    async doSortList () {
      await this.clearResult()
      await this.get(this.keyword)
    },
    broadcastCollapseCWS () {
      this.sensorData.forEach(
        (value, index) => {
          const component = this.$refs['cws' + value.id][0]
          component.collapse()
        }
      )
    },
    placeFactory (data) {
      this.map.Overlays.clear()
      let logoSize = []
      let visibleRange = []
      if (this.$vuetify.breakpoint.xs) {
        logoSize = [45, 35]
        visibleRange = [{ min: 11, max: 20 }, { min: 7, max: 10 }]
      } else {
        logoSize = [45]
        visibleRange = [{ min: 7, max: 20 }]
      }
      for (const item of data) {
        for (let index = 0; index < logoSize.length; index += 1) {
          const marker = new window.longdo.Marker(
            { lon: item.lon, lat: item.lat },
            {
              title: item.name,
              icon: {
                html:
                  `<div class="elevation-3 pa-1 white clickText" data-id="${item.id}">
                    <img alt="factory-${item.id}-logo" style="height: ${logoSize[index]}px; width: ${logoSize[index]}px;" src="${item.logo}">
                  </div>`,
                offset: { // +8 is reserve for padding
                  x: parseInt((logoSize[index] + 8) / 2),
                  y: parseInt((logoSize[index] + 8) / 2)
                }
              },
              visibleRange: visibleRange[index]
            }
          )
          this.map.Overlays.add(marker)
        }
      }
    },
    scrollListToTop () {
      const dom = document.getElementsByClassName('sensorListContainer')[0]
      dom.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    onSensorListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetchingSensor && this.factoryHasNextPage) {
        this.get(this.keyword)
      }
    },
    moveMapToLocation (location) {
      this.searchDialog = false
      this.map.location(location)
      this.map.zoom(14)
    },
    computeFirstLayout () {
      // when mounted on mobile
      const screenHeight = this.pageDom.offsetHeight
      if (this.$vuetify.breakpoint.xs) {
        let detailTopValue
        let detailHeight
        let mapHeight
        if (this.mobileDetailHeight !== 0 && this.mobileDetailTop !== 0) {
          detailTopValue = this.mobileDetailTop
          detailHeight = this.mobileDetailHeight
          mapHeight = screenHeight - detailHeight
        } else {
          detailTopValue = parseInt(screenHeight * 0.7)
          detailHeight = parseInt(screenHeight * 0.3)
          mapHeight = parseInt(screenHeight * 0.7)
        }
        this.detailDom.style.top = detailTopValue + 'px'
        this.detailDom.style.height = detailHeight + 'px'
        this.mapDom.style.height = mapHeight + 'px'
      } else {
        this.detailDom.style = null
        this.mapDom.style = null
      }
    },
    calculateHeightStart (e) {
      this.isTouch = true
    },
    calculateHeight (e) {
      const doTouch = () => {
        const clientY = e.changedTouches[0].clientY
        const screenHeight = this.pageDom.offsetHeight
        if (this.$vuetify.breakpoint.xs && clientY > screenHeight * 0.1 && clientY < screenHeight * 0.8) {
          const detailHeight = screenHeight - clientY
          const mapHeight = screenHeight - detailHeight
          this.detailDom.style.top = clientY + 'px'
          this.detailDom.style.height = detailHeight + 'px'
          this.mapDom.style.height = mapHeight + 'px'
        }
      }
      requestAnimationFrame(doTouch)
    },
    calculateHeightEnd (e) {
      this.isTouch = false
      window.dispatchEvent(new Event('resize'))
      const clientY = e.changedTouches[0].clientY
      const screenHeight = this.pageDom.offsetHeight
      const detailHeight = screenHeight - clientY
      this.$store.commit('UPDATE_DETAIL_TOP', clientY)
      this.$store.commit('UPDATE_DETAIL_HEIGHT', detailHeight)
    },
    clickSearchField () {
      this.searchDialog = true
      this.$nextTick(() => {
        // if not set timeout, iOS will bug
        setTimeout(() => {
          this.$refs.dashboardSearchField.$refs.input.focus()
        }, 600)
      })
    },
    openMenu () {
      this.$emit('openMenu', true)
    },
    openFilter () {
      this.$emit('openFilter', true)
    },
    openLogin () {
      this.$emit('openLogin', true)
    },
    searchPlaceSignalHandler (e) {
      this.isShowSearchPlace = e
    },
    emitLogoutSignal () {
      this.$emit('logoutSignal', true)
    },
    setKeyword (keyword = '') {
      this.keyword = keyword
    },
    searchClickHandler (location) {
      // search from mobile dialog
      this.userLat = location.lat
      this.userLon = location.lon
      this.basedOn = location.name
      this.isShowSearchPlace = false
      this.map.zoom(12)
      this.map.location({ lat: this.userLat, lon: this.userLon })
      this.clearResult()
      this.get(this.keyword)
    },
    async placeSensorTable (companyId) {
      this.isFetchingTable = true
      this.selectedCompanyName = this.allFactoryData.find(item => String(item.id) === String(companyId)).name
      let factoryData = this.allFactoryData.filter(item => String(item.id) === String(companyId))
      if (factoryData.length > 0) {
        factoryData = factoryData[0]
        this.tableCountCems = factoryData.countCems || 0
        this.tableCountOpms = factoryData.countOpms || 0
        this.tableCountMobile = factoryData.countMobile || 0
        this.tableCountStation = factoryData.countStation || 0
      } else {
        this.tableCountCems = 1
        this.tableCountOpms = 1
        this.tableCountMobile = 1
        this.tableCountStation = 1
      }
      // pre-render component then show dialog
      this.openSensorTableModal = true
      const promise = []
      const iterativeData = [
        {
          conditionName: 'tableCountCems',
          typeId: 1,
          targetData: 'cemsData'
        },
        {
          conditionName: 'tableCountOpms',
          typeId: 2,
          targetData: 'opmsData'
        },
        {
          conditionName: 'tableCountMobile',
          typeId: 3,
          targetData: 'mobileData'
        },
        {
          conditionName: 'tableCountStation',
          typeId: 4,
          targetData: 'stationData'
        }
      ]
      iterativeData.forEach(
        (item) => {
          if (this[item.conditionName] <= 0) return
          const itemPayload = {
            id: companyId,
            type: item.typeId,
            date: '',
            uuid: this.uuid
          }
          const itemPromise = this.$store.dispatch('sensor/getSensorData', itemPayload)
            .then(
              data => { this[item.targetData] = data }
            )
          promise.push(itemPromise)
        }
      )
      await Promise.all(promise)
      this.isFetchingTable = false
    },
    async doAdvanceSearchSearch (payload, keyword) {
      // search from default.vue
      this.setKeyword(this.keyword)
      await this.clearResult()
      await this.advanceGet({ ...payload, keyword: this.keyword })
      this.advanceSearchData = payload
    }
  },
  watch: {
    // when user change filter type do filter
    filterType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.broadcastCollapseCWS()
        this.clearResult()
        this.get(this.keyword)
      }
    },
    openSensorTableModal (to, from) {
      if (to === false) {
        if (this.tabSensorTable !== 0) this.tabSensorTable = 0
        this.cemsData = null
        this.opmsData = null
        this.mobileData = null
        this.stationData = null
      }
    }
  },
  computed: {
    ...mapState(['mobileDetailHeight', 'mobileDetailTop']),
    ...mapState('dashboard', ['factoryHasNextPage', 'allFactoryData']),
    ...mapState('user', ['uuid', 'profile_picture', 'email', 'full_name', 'isLoggingOut', 'permission', 'position']),
    ...mapState(['deviceId'])
  }
}
</script>

<style scoped>
.sensorPage {
  height: 100%;
  max-height: 100%;
  position: relative;
}

/* [start] overall screen render */
@media (max-width: 600px) {
  .sensorDetail {
    width: 100%;
    z-index: 6;
    position: absolute;
  }

  .sensorMapContainer {
    width: 100%;
    position: relative;
  }

  .sensorMapButton {
    position: absolute;
    top: 70px;
    left: 12px;
  }

  .sensorAppBar {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .sensorListContainer {
    /*height: calc(100% - 85px);*/
    overflow-y: auto;
  }

  ::v-deep .ldmap_placeholder .ldmap_navigation {
    display: none;
  }

  ::v-deep .ldmap_placeholder .ldmap_topright {
    top: 66px;
    right: 8px;
  }
}

@media (min-width: 600px) {
  ::v-deep .ldmap_placeholder .ldmap_navigation {
    display: none;
  }

  ::v-deep .ldmap_placeholder .ldmap_topleft {
    display: none;
  }

  .sensorMapButton {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .sensorDetail {
    width: min(700px, 60%);
    height: 100%;
  }

  .sensorMapContainer {
    width: calc(100% - min(700px, 60%));
    height: 100%;
    position: relative;
  }

  .sensorListContainer {
    width: 100%;
    overflow-x: hidden;
    /*height: calc(100% - 80px);*/
    overflow-y: auto;
  }
}

/* [END] overall screen render */

/* [START] filter box */
@media (max-width: 710px) {
  .filterUnit {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 0;
  }

  .filterUnitLeft {
    padding-left: 4px;
    padding-right: 4px;
    flex-grow: 1;
  }

  .filterUnitRight {
    padding-left: 4px;
    padding-right: 4px;
    flex-grow: 0;
  }

  .myFilterText {
    display: none;
  }

  .myFilterIcon {
    display: flex;
  }
}

@media (min-width: 710px) and (max-width: 880px) {
  .filterUnit {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 0;
  }

  .filterUnitLeft {
    padding-left: 12px;
    padding-right: 4px;
    flex-grow: 1;
  }

  .filterUnitRight {
    padding-left: 4px;
    padding-right: 12px;
    flex-grow: 0;
  }

  .myFilterText {
    display: none;
  }

  .myFilterIcon {
    display: flex;
  }
}

@media (min-width: 880px) and (max-width: 1000px) {
  .filterUnit {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 0;
  }

  .filterUnitLeft {
    min-width: 80px;
    justify-content: center;
    padding-left: 12px;
    padding-right: 4px;
    flex-grow: 1;
  }

  .filterUnitRight {
    padding-left: 4px;
    padding-right: 12px;
    flex-grow: 0;
  }

  .myFilterText {
    display: inline-block;
  }

  .myFilterIcon {
    display: none;
  }
}

@media (min-width: 1000px) {
  .filterUnit {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 0;
  }

  .filterUnitLeft {
    min-width: 97px;
    justify-content: center;
    padding-left: 12px;
    padding-right: 4px;
    flex-grow: 1;
  }

  .filterUnitRight {
    padding-left: 4px;
    padding-right: 12px;
    flex-grow: 0;
  }

  .myFilterText {
    display: inline-block;
  }

  .myFilterIcon {
    display: none;
  }
}

/* [END] filter box */

/* [START] mobile search dialog animation */
.search-enter-active, .search-leave-active {
  transition: top .4s;
}

.search-enter, .search-leave-to {
  top: -87px;
}

/* [END] mobile search dialog animation */

/* [START] sensor table dialog */
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  font-weight: 400;
}

/* [END] sensor table dialog */

</style>
