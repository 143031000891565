<template>
  <div>
    <v-data-table :headers="headers" :items="data" :items-per-page="999" disable-sort hide-default-footer
                  mobile-breakpoint="0">
      <template v-slot:item.code="{ item }" class="d-flex align-center">
        <span v-if="false" class="chip success white--text">{{ item.type }}</span>
        {{ item.code }}
      </template>
      <template v-for="id in headerId" v-slot:[`item.${id}`]="{ item }">
        <div :key="id">
          <div v-if="!item[id]" class="grey--text text--darken-1">
            *
          </div>
          <div v-else>
            <span v-if="item[id].isError || (translateMinusToErr && item[id].value < 0) || item[id].errorCode"
                  :class="{
                    'clickText': clickText,
                    'success--text': item[id].errMsg === 'ERR',
                    'red--text': item[id].errMsg !== 'ERR'
                  }"
                  @click="emitSensorParameter(item.id, id);
                          emitUnit(sensorData.parameters[id].unit);
                          emitMeasurementCode({measName: item.measName, code: item.code, name: sensorData.parameters[id].name});">
              {{ item[id].errMsg || item[id].errorCode }}
            </span>
            <span v-else-if="item[id].value === null" class="grey--text text--darken-1">
              -
            </span>
            <span v-else :class="{
                'success--text': item[id].severity === 1,
                'warning--text text--darken-1': item[id].severity === 2,
                'error--text': item[id].severity === 3
              }">
              <span :class="(clickText) ? 'clickText' : ''"
                    @click="emitSensorParameter(item.id, id);
                            emitUnit(sensorData.parameters[id].unit);
                            emitMeasurementCode({measName: item.measName, name: sensorData.parameters[id].name});
                           ">
                <strong>{{ numberWithCommas(item[id].value) }}</strong>
              </span>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2 myPencil" @click="emitEditSignal(item)">mdi-pencil</v-icon>
        <v-icon class="myTrash" @click="emitDeleteSignal(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <span class="grey--text text--darken-2" style="font-size: 12px;">* = ไม่มีการติดตั้ง, - = มีการติดตั้ง แต่ไม่ส่งข้อมูล, ERR = ค่าติดลบ</span>
  </div>
</template>

<script>
import utils from '../assets/js/utils'

export default {
  name: 'sensorTable',
  props: ['height', 'sensorData', 'showAction', 'hideSensor', 'clickText', 'translateMinusToErr'],
  data () {
    return {
      reservHeaders: [
        {
          text: 'จุดตรวจวัด',
          value: 'code',
          align: 'center'
        }
      ],
      sensorType: ''
    }
  },
  methods: {
    numberWithCommas (x) {
      return utils.numberWithCommas(x)
    },
    transformHeader (parameters) {
      const key = Object.keys(parameters)
      const headers = []
      headers.push({
        text: 'จุดตรวจวัด',
        value: 'measName',
        align: 'center',
        width: 100
      })
      if (!this.hideSensor) {
        headers.push({
          text: 'วันที่',
          value: 'date',
          align: 'center',
          width: '90'
        })
        headers.push({
          text: 'เวลา',
          value: 'time',
          align: 'center',
          width: '75'
        })
        key.forEach(
          item => {
            const column = {
              text: parameters[item].name + ((parameters[item].unit) ? ' (' + parameters[item].unit + ')' : ''),
              value: item,
              align: 'center'
            }
            headers.push(column)
          }
        )
      }
      if (this.showAction) {
        headers.push({
          text: '',
          value: 'actions',
          align: 'center',
          width: (this.hideSensor) ? null : 95
        })
      }
      return headers
    },
    transformData (measurements) {
      const key = Object.keys(measurements)
      const data = []
      key.forEach(
        item => {
          const obj = measurements[item]
          const row = {
            code: obj.code,
            measName: obj.measName,
            type: obj.typeName,
            typeCode: obj.type,
            geom: obj.geom,
            date: (obj.recordedDate) ? obj.recordedDate.split(' ')[0] : '-',
            time: (obj.recordedDate) ? obj.recordedDate.split(' ')[1].slice(0, 5) + ' น.' : '-',
            id: obj.id
          }
          const parametersKey = Object.keys(obj.parameters)
          parametersKey.forEach(
            item => {
              row[item] = obj.parameters[item]
            }
          )
          data.push(row)
        }
      )
      return data
    },
    emitSensorParameter (measurementId, parameterId) {
      this.$emit('clickSensorValue', { measurementId: measurementId, parameterId: parameterId })
      return null
    },
    emitMeasurementCode (code) {
      this.$emit('measurementCode', code)
      return null
    },
    emitUnit (unit) {
      this.$emit('clickUnit', unit)
    },
    emitEditSignal (item) {
      this.$emit('editBtn', item)
    },
    emitDeleteSignal (item) {
      this.$emit('deleteBtn', item)
    }
  },
  computed: {
    headerId () {
      const idList = this.headers.filter(item => !isNaN(item.value))
      return idList.map(item => item.value)
    },
    data () {
      if (!this.sensorData || Array.isArray(this.sensorData.parameters) || !this.sensorData.measurements) {
        return []
      } else {
        return this.transformData(this.sensorData.measurements)
      }
    },
    headers () {
      if (!this.sensorData || Array.isArray(this.sensorData.parameters) || !this.sensorData.parameters) {
        return this.reservHeaders
      } else {
        return this.transformHeader(this.sensorData.parameters)
      }
    }
  }
}
</script>

<style scoped>

.chip {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 24px !important;
  margin-right: 6px;
}

.clickText:hover {
  text-decoration: underline;
}

.myPencil:hover {
  color: #ffc700;
}

.myTrash:hover {
  color: #bf1c12;
}

::v-deep .v-data-table__wrapper {
  max-height: 300px;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th:not(:last-child) {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr {
  background: #F6F6F6;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}

@media (min-width: 600px) {
  /* fix column to most left */
  ::v-deep .v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 4;
    background: white;
  }

  ::v-deep .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 6;
  }

  ::v-deep .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 100px;
    z-index: 4;
    background: white;
  }

  ::v-deep .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 100px;
    z-index: 6;
  }

  ::v-deep .v-data-table__wrapper > table > tbody > tr > td:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 190px;
    z-index: 4;
    background: white;
  }

  ::v-deep .v-data-table__wrapper > table > thead > tr > th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 190px;
    z-index: 6;
  }

  ::v-deep .v-data-table__wrapper > table > thead > tr > th {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 5;
  }
}
</style>
