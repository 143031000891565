var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":999,"disable-sort":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [(false)?_c('span',{staticClass:"chip success white--text"},[_vm._v(_vm._s(item.type))]):_vm._e(),_vm._v(" "+_vm._s(item.code)+" ")]}},_vm._l((_vm.headerId),function(id){return {key:("item." + id),fn:function(ref){
var item = ref.item;
return [_c('div',{key:id},[(!item[id])?_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(" * ")]):_c('div',[(item[id].isError || (_vm.translateMinusToErr && item[id].value < 0) || item[id].errorCode)?_c('span',{class:{
                  'clickText': _vm.clickText,
                  'success--text': item[id].errMsg === 'ERR',
                  'red--text': item[id].errMsg !== 'ERR'
                },on:{"click":function($event){_vm.emitSensorParameter(item.id, id);
                        _vm.emitUnit(_vm.sensorData.parameters[id].unit);
                        _vm.emitMeasurementCode({measName: item.measName, code: item.code, name: _vm.sensorData.parameters[id].name});}}},[_vm._v(" "+_vm._s(item[id].errMsg || item[id].errorCode)+" ")]):(item[id].value === null)?_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(" - ")]):_c('span',{class:{
              'success--text': item[id].severity === 1,
              'warning--text text--darken-1': item[id].severity === 2,
              'error--text': item[id].severity === 3
            }},[_c('span',{class:(_vm.clickText) ? 'clickText' : '',on:{"click":function($event){_vm.emitSensorParameter(item.id, id);
                          _vm.emitUnit(_vm.sensorData.parameters[id].unit);
                          _vm.emitMeasurementCode({measName: item.measName, name: _vm.sensorData.parameters[id].name});}}},[_c('strong',[_vm._v(_vm._s(_vm.numberWithCommas(item[id].value)))])])])])])]}}}),{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 myPencil",on:{"click":function($event){return _vm.emitEditSignal(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"myTrash",on:{"click":function($event){return _vm.emitDeleteSignal(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('span',{staticClass:"grey--text text--darken-2",staticStyle:{"font-size":"12px"}},[_vm._v("* = ไม่มีการติดตั้ง, - = มีการติดตั้ง แต่ไม่ส่งข้อมูล, ERR = ค่าติดลบ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }