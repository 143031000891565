<template>
  <v-dialog v-model="isOpenDialog" :max-width="550" scrollable style="max-height: 80%;">
    <v-card>
      <v-card-title>
        <span class="secondary--text">ค้นหาสถานที่</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <div class="black--text pt-4">
          <div class="mb-3 px-6 px-sm-12" style="width: 100%;">
            <v-text-field v-model="searchText" dense hide-details outlined placeholder="ค้นหาที่นี่"></v-text-field>
          </div>
          <div class="resultListContainer px-6 px-sm-12" style="overflow-x: auto; height: 300px;">
            <div v-for="(item, index) in suggestData" :key="index">
              <div class="d-flex pa-2 mb-2 resultList" @click="emitLocation(item.lat, item.lon, item.name)">
                <div class="d-flex align-center pr-2">
                  <v-icon large>mdi-map-marker</v-icon>
                </div>
                <div style="width: calc(100% - 45px);">
                  <div class="pb-1 ellipsisText" style="width: 100%; font-size: 16px;" v-html="item.d"></div>
                  <div v-if="item.address && item.address.subdistrict" class="ellipsisText"
                       style="width: 100%; font-size: 12px; line-height: 1;">
                    {{ item.address.subdistrict }} {{ item.address.district }} {{ item.address.province }}
                  </div>
                  <div class="ellipsisText" style="width: 100%; font-size: 12px; line-height: 1;">
                    {{ item.address || '-' }}
                  </div>
                  <span v-if="item.lat" style="font-size: 12px; line-height: 1;">
                    {{ item.lat.toFixed(4) }}, {{ item.lon.toFixed(4) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-end pb-1" style="width: 100%">
          <v-btn block elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SearchPlace',
  props: {
    isShow: Boolean,
    value: Object
  },
  data () {
    return {
      data: {},
      isOpenDialog: false,
      searchText: '',
      suggestData: []
    }
  },
  methods: {
    async suggest (keyword) {
      // dead code bit not delete, hahaha
      this.suggestData = []
      const res = await this.$store.dispatch('mapSuggest', { keyword: keyword })
      let list = res.data
      list = list.filter(item => item.s !== 'poi3')
      list.forEach((item, index) => {
        this.$store.dispatch('mapSearch', { keyword: item.w })
          .then(search => {
            if (search.data.length > 0) {
              item.lat = search.data[0].lat
              item.lon = search.data[0].lon
              item.address = search.data[0].address
              item.name = item.w
              if (!item.address) {
                this.$store.dispatch('reverseGeocoding', { lat: item.lat, lon: item.lon })
                  .then(address => {
                    item.address = address
                  })
              }
              if (index === list.length - 1) {
                this.suggestData.splice(0, 0)
              }
            }
          })
      })
      this.suggestData = list
    },
    async search (keyword) {
      this.suggestData = []
      const res = await this.$store.dispatch('mapSearch', { keyword: keyword, dataset: 'data2p,con,change' })
      const searchData = res.data
      searchData.forEach((item, index) => {
        const i = { lat: item.lat, lon: item.lon, d: item.name, address: item.address, name: item.name }
        this.suggestData.push(i)
      })
      this.suggestData.splice(0, 0) // tell vue to update template
    },
    emitLocation (lat, lon, name) {
      this.$emit('clickLabel', { lat: lat, lon: lon, name: name })
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    searchText (to, from) {
      this.search(to)
    }
  }
}
</script>

<style scoped>
.ellipsisText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.resultList {
  background-color: rgba(0, 87, 113, 0.1);
  width: 100%;
}

.resultList:hover {
  background-color: rgba(0, 87, 113, 0.2);
  width: 100%;
  cursor: pointer;
}
</style>
